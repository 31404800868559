import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

export const useQueryStringParser = () => {
  const searchParams = useSearchParams();
  const [queryParams, setQueryParams] = useState(searchParams);

  useEffect(() => {
    if (searchParams) {
      setQueryParams(searchParams);
    }
  }, [searchParams]);

  return queryParams;
};
