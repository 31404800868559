"use client";

import { useRouter } from "next/navigation";
import { useState } from "react";
import { ContactModal, ContextMenu, Icon, Uploader } from "..";
import { createInvoice } from "../../../../apis/invoices";

type PropsT = {
  justIcon?: boolean;
  open: boolean;
  setOpen: (val: boolean) => void;
  setCloseMobile?: (val: boolean) => void;
};

export default function CreateButton(props: PropsT) {
  const { justIcon, open, setOpen, setCloseMobile } = props;
  const router = useRouter();

  const [triggerUploading, setTriggerUploading] = useState(false);

  const triggerUploaderFunc = () => {
    setTriggerUploading(true);
    setTimeout(() => {
      setTriggerUploading(false);
    }, 2000);
  };

  const handleGenerateInvoice = async () => {
    const createRequest = await createInvoice();

    if (createRequest && createRequest.invoice) {
      setCloseMobile?.(true);
      router.push(
        `/invoices/create/${createRequest.invoice.unique_identifier}`
      );
    }
  };

  return (
    <>
      <ContextMenu
        position={justIcon ? "right-end" : "bottom-start"}
        padding={16}
        buttonStyles="w-full focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 rounded-full"
        items={[
          {
            label: "Create new invoice",
            onClick: () => handleGenerateInvoice(),
            icon: "pencil-simple",
          },
          {
            label: "Upload invoice",
            onClick: () => triggerUploaderFunc(),
            icon: "upload-simple",
          },
          {
            label: "Add a contact",
            onClick: () => setOpen(true),
            icon: "address-book",
          },
        ]}
      >
        {justIcon ? (
          <div className="bg-gradient-1 hover:bg-gradient-2 focus-visible:outline-primary-500 flex h-[34px] w-[34px] items-center justify-center rounded-full focus-visible:outline focus-visible:outline-offset-1">
            <Icon icon="plus" iconStyle="fill-white" size="14" />
          </div>
        ) : (
          <div className="bg-gradient-2 hover:bg-gradient-1 inline-flex h-[41px] w-full items-center justify-center gap-2 whitespace-nowrap rounded-full border-transparent px-6 focus:outline-none">
            <Icon icon="plus" iconStyle="fill-white" size="14" />
            <span className="text-sm font-medium text-white">Add new</span>
          </div>
        )}
      </ContextMenu>
      <ContactModal open={open} setOpen={() => setOpen(false)} />

      <div className="absolute inset-x-0 ml-0 w-[85vw] md:top-40 md:ml-80">
        <Uploader
          type="invoice"
          trigger={triggerUploading}
          id="navbar"
          hidden={true}
          onComplete={() => setCloseMobile?.(true)}
        />
      </div>
    </>
  );
}
