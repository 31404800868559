"use client";

import { useAuth } from "@/state/AuthContexts";
import { Button, Modal, TextInput, useToast } from "@repo/ui";
import { useState } from "react";
import { inviteTeammate } from "../../../../apis/company";
import { UserRoleE } from "../../../features/auth/types";
import { inviteUserSchema, validateAndSetErrors } from "../../schemas";

const teammateInputs = [
  { name: "email", label: "Email", value: "" },
  { name: "first_name", label: "First name", value: "" },
  { name: "last_name", label: "Last name", value: "" },
];

const emptyInvitedUser = {
  email: "",
  first_name: "",
  last_name: "",
  role: "",
};

type PropsT = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onComplete?: () => void;
  isAuthorizedSignatory?: boolean;
};

export default function InviteTeammateModal(props: PropsT) {
  const { open, setOpen, onComplete, isAuthorizedSignatory } = props;
  const { user, getCompany } = useAuth();
  const { toast } = useToast();

  const [loading, setLoading] = useState(false);
  const [invitedUser, setInvitedUser] = useState(emptyInvitedUser);
  const [inviteError, setInviteError] = useState<ErrorT>();

  const handleInvite = async () => {
    if (user) {
      setLoading(true);

      const hasError = await validateAndSetErrors(
        inviteUserSchema,
        { email: invitedUser.email },
        setInviteError
      );

      if (!hasError) {
        await inviteTeammate(
          user.full_name,
          user.email,
          user.Company.name,
          invitedUser.email,
          invitedUser.first_name,
          invitedUser.last_name,
          isAuthorizedSignatory ? UserRoleE.FINANCE : UserRoleE.APPROVER,
          isAuthorizedSignatory
        )
          .then((req) => {
            if (!req?.success)
              throw new Error(req?.message || "Something went wrong");

            onComplete?.();
            getCompany(user.token);
            setOpen(false);
            setInvitedUser(emptyInvitedUser);
          })
          .then(() => {
            toast({
              variant: "success",
              title: `Your invite to ${invitedUser?.email} was sent successfully!`,
            });
          })
          .catch((e) => {
            console.error(e?.message);
            setInviteError({ email: e?.message || "Something went wrong" });
          });
      }
      setLoading(false);
    }
  };

  const handleCloseInviteModal = () => {
    setOpen(false);
    setInvitedUser(emptyInvitedUser);
    setInviteError({});
  };

  return (
    <Modal
      open={open}
      setOpen={handleCloseInviteModal}
      title="Invite a teammate"
    >
      <div
        className="max-w-lg space-y-4 p-3 md:p-5"
        data-testid="invite-teammate-modal"
      >
        <div>
          {
            "Invite someone to your Quickly workspace. Once they accept you'll be able to include them in approval workflows."
          }
        </div>
        {teammateInputs.map((input, index) => (
          <TextInput
            name={input.name}
            key={index}
            label={input.label}
            value={invitedUser[input.name as keyof typeof invitedUser]}
            onChange={(e) => {
              setInviteError({});
              setInvitedUser({
                ...invitedUser,
                [e.target.name]: e.target.value,
              });
            }}
            error={inviteError?.[input.name]}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleInvite();
              }
            }}
            wide
          />
        ))}

        <div className="flex flex-row justify-end space-x-3.5">
          <Button
            color="white"
            label="Cancel"
            outline
            onClick={() => handleCloseInviteModal()}
          />
          <Button
            label="Send invitation"
            loading={loading}
            onClick={() => {
              handleInvite();
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
