"use client";

import { Avatar } from "@repo/ui";
import { dateHandler } from "./helper";

type PropsT = {
  activityItem: ActivityT;
  teammates?: (UserType | ContactType)[];
};

export default function ActivityItem(props: PropsT) {
  const { activityItem, teammates } = props;
  const user = teammates?.find((e) => e.id === activityItem.issuer_id);
  const dateDifference = dateHandler(new Date(activityItem.createdAt));

  return (
    <div
      className="flex border-b border-gray-200 py-3 hover:cursor-pointer"
      data-testid="activity-item"
    >
      <Avatar user={user} small />
      <div className="flex flex-1 items-center justify-between pl-4">
        <div className="text-sm font-normal text-gray-900">
          {activityItem.message}
        </div>
        <div className="whitespace-nowrap pl-6 text-xs text-gray-500">
          {dateDifference}
        </div>
      </div>
    </div>
  );
}
