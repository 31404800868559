import { useCallback, useEffect } from "react";

export const useWarnIfUnsavedChanges = (
  changesMade: boolean,
  callback: () => boolean
) => {
  // todo probably broke this
  const handleBeforeUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (changesMade) {
        const ok = callback();
        if (!ok) {
          e.preventDefault();
          e.returnValue = "";
        }
      }
    },
    [callback, changesMade]
  );

  useEffect(() => {
    if (changesMade) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [changesMade, handleBeforeUnload]);
};
