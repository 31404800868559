import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { getActionableBillsCount } from "../../../apis/company/api";

export const useBillCountOnNavigate = () => {
  const [billCount, setBillCount] = useState(0);
  const pathname = usePathname();

  useEffect(() => {
    getActionableBillsCount()
      .then((response) => {
        if (!response?.success) throw new Error("Failed to fetch bill count");
        setBillCount(response.newBillCount);
      })
      .catch(() => setBillCount(0));
  }, [pathname]);

  return billCount;
};
