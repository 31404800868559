"use client";

import { useAuth } from "@/state/AuthContexts";
import { Button, Modal, useToast } from "@repo/ui";
import { useState } from "react";
import { forceCompanySync } from "../../../../apis/company";
import { capitalizeName } from "../../helpers";

type PropsT = {
  open: boolean;
  isBills: boolean;
  isContacts?: boolean;
  setOpen: (open: boolean) => void;
  erpType?: string;
};

export default function ErpPullSyncModal(props: PropsT) {
  const {
    open,
    setOpen,
    isBills,
    isContacts = false,
    erpType = "Accounting Software",
  } = props;
  const { user, company, getCompany, selectedEntity } = useAuth();
  const { toast } = useToast();

  const [forcingSync, setForcingSync] = useState(false);

  const forceSync = async () => {
    if (user && company) {
      setForcingSync(true);
      const response = await forceCompanySync({
        type: isBills ? "bill" : isContacts ? "contact" : "invoice",
        entityId:
          company.Connections[0]?.connection_type === "QBD"
            ? selectedEntity?.id?.toString()
            : undefined,
      });

      await getCompany(user?.token);
      if (response?.success) {
        toast({
          variant: "success",
          title: `Importing all ${isBills ? "bills" : isContacts ? "contacts" : "invoices"}, this could take a while`,
        });
      } else {
        toast({
          variant: "error",
          title:
            "An error occurred while syncing your data. Please contact support for assistance.",
        });
      }
      setForcingSync(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={`Confirm import ${isBills ? "bills" : isContacts ? "contacts" : "invoices"}?`}
    >
      <div
        className="flex flex-col p-4 text-sm text-gray-500 md:px-6 md:py-5 md:text-base"
        data-testid="erp-sync-pull-modal"
      >
        <div>
          Are you sure you want to import all{" "}
          {isBills ? "bills" : isContacts ? "contacts" : "invoices"} from your
          <span className="font-semibold"> {capitalizeName(erpType)} </span>
          account?
        </div>
        <div className="flex-end flex flex-row justify-end space-x-3.5 pt-8">
          <Button
            color="white"
            outline
            label="Cancel"
            onClick={() => {
              setOpen(false);
            }}
          />
          <Button
            color="dark"
            label={`Confirm import ${isBills ? "bills" : isContacts ? "contacts" : "invoices"}`}
            disabled={forcingSync}
            loading={forcingSync}
            onClick={() => forceSync()}
          />
        </div>
      </div>
    </Modal>
  );
}
