"use client";

import { Combobox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Badge, Icon } from "..";
import { getCompanyTags } from "../../../../apis/contacts";

type PropsT = {
  onSelect: (tag?: TagT) => void; // this is to pass the input values to calling parent
  tag?: { label: string; value: string }; // this is to be passed to component when loading page
  error?: string;
  wide?: boolean;
};

const TagSearch = (props: PropsT) => {
  const { onSelect, error, tag, wide } = props;

  const [selectedTag, setSelectedTag] = useState<
    { label: string; value: string } | undefined
  >(tag);

  const [query, setQuery] = useState("");
  const [loadingTags, setLoadingTags] = useState(false);

  const [allTags, setAllTags] = useState<TagT[]>([]);

  const getTags = useCallback(async (sanitizedQuery?: string) => {
    setLoadingTags(true);
    getCompanyTags({
      offset: 0,
      limit: 20,
      query: sanitizedQuery || "",
    })
      .then((req) => {
        if (!req.success) throw Error(req.message);
        // Do some sort/grouping here
        const tagsSorted = restructureData(req.tags);

        setAllTags(flattenArray(tagsSorted));
        return req;
      })
      .catch((err) => console.error(err))
      .finally(() => setLoadingTags(false));
  }, []);

  useEffect(() => {
    getTags();
  }, [getTags]);

  const handleSearch = async (queryString: string) => {
    setQuery(queryString);

    const sanitizedQuery = queryString.toLowerCase().trim();

    if (sanitizedQuery.length < 3) {
      getTags(sanitizedQuery);
    } else {
      setLoadingTags(true);

      getTags(sanitizedQuery);
    }
  };

  const handleSelect = async (tag: TagT) => {
    setSelectedTag({ label: tag.name, value: tag.id.toString() });
    onSelect(tag);
    setQuery("");
  };

  const restructureData = (data: TagT[] = []) => {
    const children = data.filter((object) => object.parent_id);
    const parents = data.filter((object) => !object.parent_id);

    for (let i = 0; i < children.length; i++) {
      if (children[i] && children[i].parent_id) {
        const parentIndex = parents
          .map((object) => object.id)
          .indexOf(children[i].parent_id || 0);

        if (!parents[parentIndex].children) {
          parents[parentIndex].children = [];
        }

        parents[parentIndex]?.children?.push(children[i]);
      }
    }
    return parents;
  };

  function flattenArray(arr: TagT[]) {
    const result: TagT[] = [];

    function recurse(items: TagT[]) {
      for (const item of items) {
        // Add the current item to the result
        result.push({
          id: item.id,
          name: item.name,
          type: item.type,
          parent_id: item.parent_id,
          Parent: item?.Parent,
        });

        // If the item has children, recurse into them
        if (item.children) {
          recurse(item.children);
        }
      }
    }

    recurse(arr);
    return result;
  }

  return (
    <>
      <Combobox
        as="div"
        onChange={(tag: TagT) => {
          handleSelect(tag);
        }}
        className={clsx("ContactSearch relative w-full max-w-full", {
          "w-full md:w-80": !wide,
        })}
        nullable
      >
        <>
          <div className="pointer-events-none absolute left-0 top-3.5 pl-4">
            <Icon icon="magnifying-glass" size="20" iconStyle="fill-gray-500" />
          </div>

          <Combobox.Input
            name="comboboxInput"
            placeholder=" "
            type="text"
            autoComplete="off"
            value={query}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            className={clsx(
              "z-10 block w-full appearance-none rounded-lg border border-gray-300 bg-white px-[15px] pb-2.5 pl-10 pr-8 pt-4", // input field
              "peer placeholder:text-transparent focus:border-gray-900 focus:pb-2.5 focus:pt-4 focus:outline-none focus:ring-0", // input state
              "overflow-ellipsis text-sm text-gray-900 autofill:text-sm autofill:text-gray-900", // text
              {
                "!border-orange-600 !bg-orange-100": error,
              }
            )}
          />

          <Combobox.Label
            className={clsx(
              "absolute left-10 top-3.5 cursor-text text-sm text-gray-500",
              "origin-[0] -translate-y-3 scale-75 transform duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-autofill:-translate-y-3 peer-autofill:scale-75 peer-focus:-translate-y-3 peer-focus:scale-75",
              "transition-all"
            )}
          >
            {selectedTag ? (
              <Badge label={selectedTag.label} color="gray" />
            ) : (
              "Search tags..."
            )}
          </Combobox.Label>

          <Combobox.Button
            data-testid="contact-search-trigger"
            className="absolute top-0 z-0 h-12 w-full bg-transparent hover:cursor-text"
          ></Combobox.Button>

          {(query?.length > 0 || tag) && (
            <div
              className="absolute right-0 top-3.5 cursor-pointer pr-3"
              data-testid="clear-button"
              onClick={() => {
                setSelectedTag(undefined);
                setQuery("");
                onSelect(undefined);
                getTags();
              }}
            >
              <Icon
                icon="x-circle"
                size="20"
                iconStyle="fill-gray-400 hover:fill-gray-900"
              />
            </div>
          )}

          {error && (
            <div className="mt-1 flex flex-row items-center text-sm font-semibold text-orange-600">
              <Icon
                icon="warning-fill"
                size="16"
                iconStyle="fill-orange-600 mr-2"
              />
              {error}
            </div>
          )}

          <Transition
            enter="transition duration-30 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-300 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className="absolute z-20 w-full"
          >
            <Combobox.Options className="absolute z-10 mt-2 max-h-80 min-h-5 w-full overflow-y-auto rounded-lg border border-gray-200 bg-white p-4 shadow-lg">
              <li data-testid="contact-search-contacts" key="contacts">
                <div className="flex justify-between">
                  <h2 className="bg-white text-sm font-semibold text-gray-900">
                    Tags
                  </h2>
                </div>

                {loadingTags ? (
                  <div className="my-4 text-center text-sm text-gray-500">
                    Loading...
                  </div>
                ) : (
                  <ul className="my-4 space-y-2">
                    {allTags.length > 0 ? (
                      allTags.slice(0, 5).map((tag) => (
                        <Combobox.Option key={tag.id} value={tag} as={Fragment}>
                          {({ active }) => (
                            <li
                              className={clsx(
                                "flex cursor-pointer select-none flex-col rounded-lg bg-gray-50 px-3 py-2 hover:bg-gray-100",
                                active && "!bg-gray-100"
                              )}
                            >
                              <div className="flex justify-between ">
                                <span className="text-sm text-gray-900">
                                  {tag.name}
                                </span>
                                <Icon
                                  icon="arrow-up-right"
                                  iconStyle="fill-gray-900"
                                  size="14"
                                />
                              </div>
                              <span className="text-xs text-gray-500">
                                {tag.Parent?.name || "All"}
                              </span>
                            </li>
                          )}
                        </Combobox.Option>
                      ))
                    ) : (
                      <div className="text-center text-sm text-gray-500">
                        No tags found
                      </div>
                    )}
                  </ul>
                )}
              </li>
            </Combobox.Options>
          </Transition>
        </>
      </Combobox>
    </>
  );
};

export default TagSearch;
